<template>
	<el-dialog top="1vh" title="运费核定" width="1050px" :visible.sync="is_show_in_page" @close="edit_close">
		<el-form class="form_ordinary" label-width="100px">


			<div class="big_tit" style="margin:0">货物信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.cargo_name_type"></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="上游单号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装卸货地信息/人员车辆信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input v-model="form.case_addr_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input v-model="form.aim_addr_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="承运人" style="width:315px">
					<el-input class="el_inner_width" v-model="form.truck_owner_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机" style="width:315px">
					<el-input class="el_inner_width" v-model="form.driver_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车辆" style="width:332px">
					<el-input class="el_inner_width" v-model="form.truck_text" disabled></el-input>
				</el-form-item>
			</div>


			<!-- <div class="big_tit">原运费数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主需付运费" label-width="110px">
					<el-input class="el_inner_width" v-model="form.cost_info.freight_total" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="剩余需付运费" label-width="110px">
					<el-input class="el_inner_width" v-model="freight_total_surplus" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="平台需结运费" label-width="110px" style="margin-top: 20px;">
					<el-input class="el_inner_width" v-model="form.cost_info.give_carrier_total" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="剩余需结运费" label-width="110px" style="margin-top: 20px;">
					<el-input class="el_inner_width" v-model="give_carrier_total_surplus" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
			</div> -->
            <div class="big_tit">运费数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="总运费" label-width="110px">
					<el-input class="el_inner_width" v-model="form.cost_info.freight_total" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="服务费" label-width="110px">
					<el-input class="el_inner_width" v-model="form.cost_info.service_charge" disabled style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
			
				<!-- <el-form-item class="el_form_item" label="货主需付运费" label-width="110px" style="margin-top: 20px;">
					<el-input class="el_inner_width" v-model="new_freight_total" type="number" @input="freight_total_input" :disabled="form.cost_info.calc_mode==2" clearable style="width:300px">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-button style="margin-top: 27px;margin-left: 6px;" @click="cost_count" size="mini" type="text">计算</el-button> -->
			</div>	
			<div class="big_tit">收款人/收款人信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人手机号" style="width:320px" >
					<el-input class="el_inner_width" v-model="form.truck_owner_tel" >
						
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款银行卡" style="width:320px">
					<el-input class="el_inner_width" v-model="truck_owner_card_num" type="number" disabled>
						
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit">运单信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车日期">
					<el-date-picker 
						v-model="new_start_time"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="限定当日" style="width:192px">
					<el-select class="el_inner_width" v-model="new_start_time_is_check">
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="上游单号" style="width:320px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>

			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="备注" style="width:320px">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="运单备注" clearable></el-input>
				</el-form-item>

			</div>
			<div class="tab1_inner" v-if="form.is_insure">
				<el-form-item class="el_form_item" label="线路保险"  style="width:388px">
					<el-select class="el_inner_width" v-model="form.is_insure" disabled>
						<el-option label="未购买保险" value="2"></el-option>
						<el-option label="已购买保险" value="1"></el-option>
					</el-select>
				</el-form-item>
			</div>
			
			<div class="big_tit">新运费数据/重量体积</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_weight" type="number">
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_volume" type="number" disabled>
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:320px">
					<el-input class="el_inner_width" v-model="tord_info.distance" type="number" disabled>
						<div style="margin-right: 4px;" slot="suffix">公里</div>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费单价" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_weight_price" type="number" @input="edit_cargo_weight(form.cargo_weight_price,'price')">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="发车重量" style="width:320px">
					<el-input class="el_inner_width" v-model="form.cargo_weight_start" type="number" @input="edit_cargo_weight(form.cargo_weight_start,'start')">
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货重量" style="width:320px" >
					<el-input class="el_inner_width" v-model="form.cargo_weight_arrived" type="number" @input="edit_cargo_weight(form.cargo_weight_arrived,'arrived')">
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>

			</div>
			<div class="tab1_inner" v-if="tord_info.settlement_type==1">
				<el-form-item class="el_form_item" label="货物单价" style="width:320px" >
					<el-input class="el_inner_width" v-model="form.cargo_price" type="number"  @input="edit_cargo_weight(form.cargo_price,'damage_weight')">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="允许货损" style="width:320px" >
					<el-input class="el_inner_width" v-model="form.damage_weight" type="number"  @input="edit_cargo_weight(form.damage_weight,'damage_weight')">
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="路损扣减" style="width:320px" >
					<el-input class="el_inner_width" v-model="damage_weight_price" type="number">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>

			</div>
			<div class="tab1_inner" >
					<el-form-item class="el_form_item" label="给司机" style="width:320px">
					<el-input class="el_inner_width" v-model="form.give_driver_total"  @input="give_driver_proportion(form.give_driver_total)">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="给车队长"  style="width:320px">
					<el-input class="el_inner_width" v-model="form.give_truck_owner_total" @input="give_truck_owner_proportion(form.give_truck_owner_total)">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="总运费"  style="width:280px" v-if="is_show_xf">
					<el-input class="el_inner_width" v-model="new_freight_total" @input="freight_total_input">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-button style="margin-top: 6px;margin-left: 6px;" @click="cost_count" size="mini" type="text">计算</el-button>
			
				
			</div>
			<el-form-item class="el_form_item"  style="width:320px;margin-left: 14px;" v-if="class_hide && form.insure_total>0">
				<el-checkbox v-model="manual_ver_checked" @change="manual_ver_change">保费从司机运费扣</el-checkbox>
			</el-form-item>
			<div class="tab1_inner" v-if="class_hide">
				<el-form-item class="el_form_item" label="保障服务费" style="width:320px">
					<el-input class="el_inner_width" v-model="form.insure_total" disabled>
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物金额" style="width:320px">
					<el-input class="el_inner_width" v-model="form.goods_total"  @input="insure_count(form.goods_total)">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</el-dialog>
</template>
<script>
import {mapState} from 'vuex'
	export default {
		props:{
			is_show:Number,
			tord_info:Object,
			damage_weight:String
		},
		data() {
			return {
				manual_ver_checked:true, // 保费是否从司机运费扣除
				class_hide:false,//是否显示
				//页面是否显示
				is_show_in_page:false,

				//已支付和支付中的金额
				payed_and_paying:0,

				//剩余货主需付金额
				freight_total_surplus:0,

				//已结算金额
				settled_total:0,

				//剩余平台需结金额
				give_carrier_total_surplus:0,

				//支付记录条数
				payed_list_total:0,

				//新货主需付
				new_freight_total:0,
				//记录原本运费
				new_freight_total1:0,
				//新司机需结
				new_give_carrier_total:0,

				//新的发车日期
				new_start_time:null,

				//新的发车日期校验方式
				new_start_time_is_check:'2',
				
				is_show_xf:true,	//是否显示货主需付

				insure_num:'',//保费点数
				//表单
				form:{
					cargo_name_type:'',//货物名称和类型
					out_trade_num:'',//上游单号
					cargo_weight:'',//货物重量和体积
					cargo_volume:'',//货物重量和体积
					case_addr_text:'',//装货地详细地址
					aim_addr_text:'',//卸货地详细地址
					truck_owner_text:'',//承运人信息
					driver_text:'',//司机信息
					truck_text:'',//货车信息
					cost_info:{},//费用信息
					cargo_weight_start:"",//发车重量
					cargo_weight_arrived:'',//到货重量
					cargo_weight_price:'',//单价
					truck_owner_tel:'',//收款人手机号
					damage_weight:'0',//货损吨数
					cargo_price:'',//货物单价
					give_driver_total:'',//给司机得金额
					give_truck_owner_total:'',//给车队长得金额
					is_insure:'',//是否已购买保险
					insure_total:'',//保险费
					goods_total:'',//货物金额
					mark:'',//运单备注
				},
				damage_weight_price:'',//路损金额
				truck_owner_card_num:'',//收款人银行卡号

				give_driver_total_1:'',//初始给司机得金额
				// is_show_tel:['13333574703','13453497870','19190503399','18915059081','18056918989','13453497870','18864512345'],// 18864512345测试环境
				is_show_tel:['18915059081','18056918989','18864512345','19190503399','13453497870','13834349811','13333574703','13388120088','18161305021','15983823418','13118110801','13980112099',
				'18111500986',
				'15183803894',
				'13508000881',
				'18227595781',
				'13981027972',
				'18560349949',
				'15895708987',
				'13967946105',
				'13205638817',
				'18252067890',
				'13783981152'
				],
				insure_type2_tel:['18864512345','18161305021','13508000881','18227595781','13388120088','19190503399','13453497870','13834349811','13333574703',
				'18111500986',
				'15183803894',
				'13981027972',
				'15983823418',
				'13118110801',
				'13980112099',
				'18560349949',
				'15895708987',
				'13967946105',
				'13205638817',
				'18252067890',
				'13783981152'
				], 	//保费从总运费里扣掉 谁收款多，从谁身上扣   王氏恒通四家   万博四家也要
				
				//测试环境货主 18864512345
				//王氏四家 18161305021 13508000881 18227595781 13388120088
				//万博四家 '19190503399','13453497870','13834349811','13333574703'
				
			}
		},
		computed:{
			...mapState(["company_info",'user_info'])
		},
		watch:{
			is_show(new_data){
				if(new_data){
					if(this.is_show_tel.indexOf(this.user_info.tel)!=-1){
						this.class_hide =true
					}
					this.is_show_in_page=true;
					this.set_buf_data(this.tord_info)
					//弹窗页面初始化
				    this.init();

					this.insure_num = this.company_info.insure_num
				}
			},
		},
		methods: {
			//保费是否从司机运费中扣除
			manual_ver_change(vuale){
				//从司机运费扣除
				if(vuale){
					//勾选 给司机金额 - 保费
					let total =  Number(this.give_driver_total_1)- Number(this.form.insure_total)
					if(total <0){
						total = 0
					}
					this.form.give_driver_total =total
					//重新计算货主需付
					this.new_freight_total = Number(this.form.give_driver_total) + Number(this.form.insure_total)+Number(this.form.give_truck_owner_total)
				}else{
					//不勾选 给司机金额 + 保费
					this.form.give_driver_total = Number(this.give_driver_total_1)+ Number(this.form.insure_total)
					// 重新计算货主需付
					this.new_freight_total = Number(this.form.give_driver_total) +Number(this.form.give_truck_owner_total)
				}
			},
			//保费修改
			insure_change(num){
				let type =1
				
				if(this.insure_type2_tel.indexOf(this.user_info.tel)>-1){//保费要从运费里扣掉
					type=2;
				}

				//账户1
				if(type ==1){
					this.new_freight_total = Number(this.new_freight_total1) + Number(num)
				}else{
					let give_driver_total =Number(this.form.give_driver_total) //给司机
					if(give_driver_total<0){
						give_driver_total = 0
					}
					let give_truck_owner_total =Number( this.form.give_truck_owner_total) //给车队
					if(give_truck_owner_total<0){
						give_truck_owner_total = 0
					}
					let new_freight_total = Number(this.new_freight_total - num) //总运费(总运费减保费)
					if(new_freight_total<0){
						new_freight_total = 0
					}
					if(give_driver_total >give_truck_owner_total){
						this.form.give_truck_owner_total =0
						this.form.give_driver_total =new_freight_total
					}else if(give_driver_total === give_truck_owner_total){
						this.form.give_truck_owner_total =0
						this.form.give_driver_total =new_freight_total
					}else{
						this.form.give_driver_total =0
						this.form.give_truck_owner_total =new_freight_total
					}
				}
			},
			//4.11货主端不能改 只能显示 4.17号重新开放 改为接口触发
			insure_count(goods_total){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_insure_total',
						goods_total
					},callback:(data)=>{
						this.form.insure_total = data.insure_total
						//调接口
						this.insure_change(data.insure_total)
					}
				
				})
				// if(num ==0){
				// 	this.form.insure_total = 0
				// 	return
				// }
				// let insure_num = Number(num *this.insure_num).toFixed(2)
				// if(insure_num<5){
				// 	this.form.insure_total = 5
				// }else{
				// 	this.form.insure_total = insure_num
				// }
			},
			give_driver_proportion(value){
				let num = 0
				if(value > Number(this.new_freight_total)){
					this.$my.other.msg({
						type:'info',
						str:'修改金额大于总运费',
					});
					// num= this.new_freight_total - this.form.give_truck_owner_total-this.form.insure_total
					// this.form.give_driver_total = num.toFixed(2)
					return
				}
				// num= this.new_freight_total-value-this.form.insure_total
				// this.form.give_truck_owner_total  = num.toFixed(2)
				//总运费 = 给司机 + 给车队 + 保费
				
				this.form.give_driver_total=value;
				this.form.give_truck_owner_total= (Number(this.new_freight_total)- Number(this.form.give_driver_total)).toFixed(2)-Number(this.form.insure_total)
				if(this.form.give_truck_owner_total<0){
					this.form.give_truck_owner_total= 0
				}
				//this.new_freight_total = Number(this.form.give_truck_owner_total) + Number(this.form.give_driver_total) + Number(this.form.insure_total)
			},
			give_truck_owner_proportion(value){
			// 	let num = 0
				if(value > Number(this.new_freight_total)){
					this.$my.other.msg({
						type:'info',
						str:'修改金额大于总运费',
					});
					// num=this.new_freight_total-this.form.give_driver_total-this.form.insure_total
					// this.form.give_truck_owner_total = num.toFixed(2)
					return
				}
			
			// 	num= this.new_freight_total-value-this.form.insure_total
			// 	this.form.give_driver_total  = num.toFixed(2)
				
				//总运费 = 给司机 + 给车队 + 保费
				this.form.give_truck_owner_total=value;
				this.form.give_driver_total= (Number(this.new_freight_total)- Number(this.form.give_truck_owner_total)).toFixed(2)-Number(this.form.insure_total)
				if(this.form.give_driver_total<0){
					this.form.give_driver_total= 0
				}
				// this.new_freight_total = Number(this.form.give_truck_owner_total) + Number(this.form.give_driver_total) + Number(this.form.insure_total)
			},
			// 弹窗关闭
			edit_close(){
				this.form.damage_weight ='1'
			},
			init(){
				//读取单价信息
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_price',
						truck_tord_num:this.tord_info.truck_tord_num
					},
					callback:(data)=>{
						if(data.cargo_weight_price){
							this.form.cargo_weight_price=data.cargo_weight_price;
						}else{
							this.form.cargo_weight_price = 0.00
						}
					},
				});
			},
			edit_cargo_weight(value,type){
				//修改货主重量 不掉接口 计算运费
				if(this.form.damage_weight ==0 && type !="damage_weight" && type !="price"&& type!='start'){
					this.form.cargo_weight=value; 
					this.cost_count()
					return
				}
				
				// if(type !='price'){
                    
				// }
				if(value>0){
					var damage_weight
					if(this.tord_info.settlement_type==1){
						 damage_weight =this.form. damage_weight
					}
					
					this.$my.net.req({
						take_over_control:1,
						data:{
							mod:'truck_tord_real_time',
							ctr:'edit_cargo_weight',
							truck_tord_num:this.tord_info.truck_tord_num,
							cargo_weight_start:this.form.cargo_weight_start,
							cargo_weight_arrived:this.form.cargo_weight_arrived,
							price:this.form.cargo_weight_price,
							damage_weight,
							cargo_price:this.form.cargo_price
						},callback:(data)=>{
							if(data.code ==0){
								this.new_freight_total = data.msg.freight_total
								this.new_give_carrier_total = data.msg.freight_total
								var  weight_num = Number(this.form.cargo_weight_start)-Number(this.form.cargo_weight_arrived)-Number(this.form.damage_weight)
								if(weight_num>0){
									var difference = weight_num*this.form.cargo_price
									this.damage_weight_price =difference.toFixed(2)
								}else{
									this.damage_weight_price  = 0
								}
							}
						}
					})
				}else{
					// this.cost_count();
				}
				
			},
			cost_count1(num){
				let give_driver_total =Number(this.form.give_driver_total)
				let give_truck_owner_total =Number( this.form.give_truck_owner_total)
				
				if(!this.form.insure_total){
					this.form.insure_total=0;
				}
				
				if(give_driver_total >give_truck_owner_total){
					this.form.give_truck_owner_total =0
					this.form.give_driver_total =num
					
					//自动判断，是否要扣掉保费
					if(this.insure_type2_tel.indexOf(this.user_info.tel)>-1){//保费要从运费里扣掉
						this.form.give_driver_total=Number(num)-Number(this.form.insure_total)
					}
					
				}else if(give_driver_total === give_truck_owner_total){
					this.form.give_truck_owner_total =0
					this.form.give_driver_total =num
					
					if(this.insure_type2_tel.indexOf(this.user_info.tel)>-1){//保费要从运费里扣掉
						this.form.give_driver_total=Number(num)-Number(this.form.insure_total)
					}
					
				}else{
					this.form.give_driver_total =0
					this.form.give_truck_owner_total =num
					
					if(this.insure_type2_tel.indexOf(this.user_info.tel)>-1){//保费要从运费里扣掉
						this.form.give_truck_owner_total=Number(num)-Number(this.form.insure_total)
					}
					
				}

				//总运费 = 给司机 + 给车队 + 保费
				let f1=Number(this.form.give_truck_owner_total) + Number(this.form.give_driver_total) + Number(this.form.insure_total);
				this.new_freight_total = f1.toFixed(2)
			},
			//费用计算
			cost_count(){
				//没有单价 不计算
				if(this.form.cargo_weight_price <=0){
					return
				}
				
				if(!this.form.damage_weight){
					this.form.damage_weight=0;
				}
				
				let f1=Number(this.form.cargo_weight_start)-Number(this.form.cargo_weight_arrived);

				if(f1 >= Number(this.form.damage_weight) && f1>0){
					//路损吨数
					var  weight_num = Number(this.form.cargo_weight_start)-Number(this.form.cargo_weight_arrived)-Number(this.form.damage_weight)
					//路损扣减
					var difference = weight_num*this.form.cargo_price
					if(weight_num>0){
						//有路损 
						this.damage_weight_price =difference.toFixed(2)
					}else{
						//无路损 扣减为0 
						this.damage_weight_price  = 0
					}
					// 货主需付
					// 货物吨数 * 单价 - 路损金额
					var price = this.form.cargo_weight *  this.form.cargo_weight_price - difference
					//置入
					// this.new_freight_total = price
					// this.new_freight_total =Number(this.new_freight_total).toFixed(2)
					
					this.cost_count1(Number(price).toFixed(2))
				}else{
					// 没有
					let cost  = (this.form.cargo_weight * this.form.cargo_weight_price).toFixed(2)
					// this.new_freight_total = (this.form.cargo_weight * this.form.cargo_weight_price).toFixed(2)
					this.cost_count1(Number(cost).toFixed(2))
					// this.new_freight_total = Number(this.new_freight_total).toFixed(2)
				}
				
				// return;
				// if(this.form.cost_info.calc_mode==1){
				// 	this.cost_count1(this.new_freight_total)
				// 	// this.new_give_carrier_total=this.new_freight_total
				// }
			},
			//提交
			sub(){

				//新的货主需付金额不能低于已付金额
				if(this.new_freight_total<this.payed_and_paying){
					this.$my.other.msg({
						type:'warning',
						str:'新的货主需付金额不能低于已付金额',
					});
					return;
				}

				//新的平台需结金额不能低于已结金额
				// if(this.new_give_carrier_total<this.settled_total){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'新的平台需结金额不能低于已结金额',
				// 	});
				// 	return;
				// }

				//发车日期
				let new_start_time_1=null;
				let new_start_time_2=null;
				if(this.new_start_time){
					let start_time_1=this.new_start_time[0].getTime();
					let start_time_2=this.new_start_time[1].getTime();
					let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
					// if(start_time_1<now_time){
					// 	this.$my.other.msg({
					// 		type:"warning",
					// 		str:'发车日期起点不能早于当前时间'
					// 	});
					// 	return;
					// }
					new_start_time_1=(start_time_1/1000).toFixed(0)
					new_start_time_2=(start_time_2/1000).toFixed(0)
				}

				//发车日期是否强制校验
				let new_start_time_is_check=null;
				if(['1','2'].indexOf(this.new_start_time_is_check)==-1){
					this.$my.other.msg({
						type:"warning",
						str:'请确定发车日期是否限定当日'
					});
					return;
				}else{
					new_start_time_is_check=this.new_start_time_is_check
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'修改运费",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_edit_by_shipper',
								truck_tord_num:this.tord_info.truck_tord_num,
								freight_total:this.new_freight_total,
								give_carrier_total:this.new_give_carrier_total,
								start_time_1:new_start_time_1,
								start_time_2:new_start_time_2,
								start_time_is_check:new_start_time_is_check,
								cargo_weight:this.form.cargo_weight,
								cargo_weight_start:this.form.cargo_weight_start,
								cargo_weight_arrived:this.form.cargo_weight_arrived,
								damage_weight:this.form.damage_weight,
								out_trade_num:this.form.out_trade_num,
								mark:this.form.mark,
								price:this.form.cargo_weight_price,
								cargo_price:this.form.cargo_price,
								cargo_name:this.form.cargo_name_type,
								give_truck_owner_total:this.form.give_truck_owner_total,
								give_driver_total:this.form.give_driver_total,
								insure_total:this.form.insure_total,
								goods_total:this.form.goods_total
							},
							callback:(data)=>{
								
								//报告结果
								this.$my.other.msg({
									type:'success',
									str:'操作成功',
								});

								//关闭
								this.is_show_in_page=false;

								//通知
								this.$emit("edited");
							}
						});
					}
				})
			},

			//切换结算方式
			calc_mode_change(){
				if(this.form.cost_info.calc_mode==1){
					this.new_give_carrier_total=this.new_freight_total
				}
			},

			//新货主需付变化
			freight_total_input(){
				//修改总运费 司机金额 = 总金额 - 给车队长 - 保费
				this.form.give_driver_total= (Number(this.new_freight_total)- Number(this.form.give_truck_owner_total)-Number(this.form.insure_total)).toFixed(2)
				if(this.form.give_driver_total<0){
					this.form.give_driver_total = 0
				}
				if(this.form.cost_info.calc_mode==1){
					this.new_give_carrier_total=this.new_freight_total
				}
			},

			//置入缓存数据
			set_buf_data(tord_info){
				console.log(tord_info)
				if(tord_info.truck_owner_info.bankcard_info){
					this.truck_owner_card_num = tord_info.truck_owner_info.bankcard_info.card_num
				}else{
					this.truck_owner_card_num = ''
				}
				this.damage_weight_price = 0
				//货物名称和类型
				this.form.truck_owner_tel = tord_info.truck_owner_info.tel
				this.form.cargo_name_type=tord_info.cargo_name
				this.form.mark=tord_info.mark
				this.form.cargo_weight=tord_info.cargo_weight
				this.form.cargo_volume=tord_info.cargo_volume
				this.form.damage_weight = this.damage_weight
				this.form.out_trade_num = tord_info.out_trade_num
				this.form.cargo_price = tord_info.cargo_price
				this.form.case_addr_text=tord_info.case_prov+'/'+tord_info.case_city+tord_info.case_county+'/'+tord_info.case_other_obj.addr_info
				this.form.aim_addr_text=tord_info.aim_prov+'/'+tord_info.aim_city+tord_info.aim_county+'/'+tord_info.aim_other_obj.addr_info
				this.form.truck_owner_text=tord_info.truck_owner_info.name+'/'+tord_info.truck_owner_info.tel
				this.form.driver_text=tord_info.driver_info.name+'/'+tord_info.driver_info.tel
				this.form.truck_text=tord_info.truck_info.truck_plate_num+'/'+tord_info.truck_info.truck_type_name+'/'+tord_info.truck_info.truck_type_name2
				this.form.cost_info=tord_info.cost_info
				this.form.cargo_weight_start = tord_info.cargo_weight_start
				this.form.cargo_weight_arrived = tord_info.cargo_weight_arrived
				this.form.give_driver_total = tord_info.cost_info.give_driver_total
				this.give_driver_total_1 = tord_info.cost_info.give_driver_total
				this.form.insure_total = tord_info.cost_info.insure_total
				this.form.goods_total = tord_info.cost_info.goods_total
				this.form.give_truck_owner_total = tord_info.cost_info.give_truck_owner_total
				//线路信息
				// this.form.is_insure=tord_info.bl_info.is_insure;
				//发车时间
				if(tord_info.case_other_obj.start_time_1 && tord_info.case_other_obj.start_time_2){
					this.new_start_time=[
						new Date(tord_info.case_other_obj.start_time_1*1000),
						new Date(tord_info.case_other_obj.start_time_2*1000)
					];
				}
				
			

				//发车时间强制校验
				if(['1','2'].indexOf(tord_info.case_other_obj.start_time_is_check)!=-1){
					this.new_start_time_is_check=tord_info.case_other_obj.start_time_is_check
				}

				//读取支付记录
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						truck_tord_num:tord_info.truck_tord_num,
						is_get_truck_owner_info:false,
						is_get_driver_info:false,
						is_get_tord_info:false,
						num:100000,
					},
					callback:(data)=>{

						//已支付和支付中的金额
						let payed_and_paying=0

						//已结算金额
						let settled_total=0

						for(var payed_item of data.list){


							//已支付和支付中的金额
							if(payed_item.pay_status!=4){
								if(payed_item.calc_mode==1 && payed_item.pay_status ==3){
									payed_and_paying=parseFloat(Number(payed_item.cost_info.truck_owner_settled_total) + Number(payed_item.cost_info.driver_settled_total));
								}else if(payed_item.calc_mode==2){
									payed_and_paying+=parseFloat(payed_item.this_payed);
								}
							}
						}
						if(data.list.length !=0){
							//已结算金额
						    settled_total+=parseFloat(Number(payed_item.cost_info.truck_owner_settled_total) + Number(payed_item.cost_info.driver_settled_total));
						}
						
						//已支付和支付中的金额
						this.payed_and_paying=payed_and_paying

						//剩余货主需付金额
						this.freight_total_surplus=Number(tord_info.cost_info.freight_total-this.payed_and_paying).toFixed(2);
						//已结算金额
						this.settled_total=settled_total;
						//剩余平台需结金额
						this.give_carrier_total_surplus=Number(tord_info.cost_info.give_carrier_total-this.settled_total).toFixed(2);

						//支付记录条数
						this.payed_list_total=data.list.length

						//新货主需付
						this.new_freight_total=tord_info.cost_info.freight_total
                        this.new_freight_total1=tord_info.cost_info.freight_total
						//新司机需结
						this.new_give_carrier_total=tord_info.cost_info.give_carrier_total
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	
</style>